import { bool } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { PropTypes as T } from 'ember-prop-types';
import ErrorFormatter from 'gigshq/utils/error-formatter';
import { task } from 'ember-concurrency';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

import rescrapeFacebook from 'gigshq/graphql/mutations/facebook-rescrape';

export default Component.extend(Localizable(translations), {
  propTypes: {
    venueCategories: T.array.isRequired,
    isDirty: T.bool.isRequired,
    isPending: T.bool.isRequired,
    data: T.nullable(T.object).isRequired,
    errors: T.nullable(T.object).isRequired,
    name: T.string.isRequired,
    description: T.string.isRequired,
    streetAddress: T.string.isRequired,
    city: T.string.isRequired,
    country: T.string.isRequired,
    postalCode: T.string.isRequired,
    formattedAddress: T.string.isRequired,
    profileImage: T.nullable(T.object).isRequired,
    email: T.nullable(T.string).isRequired,
    phoneNumber: T.nullable(T.string).isRequired,
    latitude: T.number.isRequired,
    longitude: T.number.isRequired,
    capacityCount: T.nullable(T.number).isRequired,
    venueCategoryId: T.string.isRequired,
    socialProfile: T.object.isRequired,
    openingHours: T.array.isRequired,
    images: T.array,
    fbEventsUrl: T.string,
    setProperty: T.func.isRequired,
    setFormSocialProfileValue: T.func.isRequired,
    markFormAsUploading: T.func.isRequired
  },

  apollo: service(),
  i18n: service(),
  tagName: 'form',

  pendingUploadCount: 0,

  shouldDisplayProfilePictureUploader: bool('venueId'),

  rescrapeFacebook: task(function*(venueId) {
    const mutation = rescrapeFacebook;
    const variables = {venueId};

    try {
      yield this.apollo.mutationOperation()({mutation, variables}, 'rescrapeFacebook');

      this.flashMessages.success(this.localTranslation('fb_import.success'));
    } catch (errors) {
    }
  }).drop(),

  selectedVenueCategory: computed(
    'venueCategories.[]',
    'venueCategoryId',
    function() {
      return this.venueCategories.find(({id}) => id === this.venueCategoryId);
    }
  ),

  sortedVenueCategories: computed('venueCategories.[]', function() {
    const venueCategories = this.venueCategories.map((entry) => {
      entry.translatedName = this.i18n.t('general.venue_categories.' + entry.name).string;
      return entry;
    });

    return venueCategories.sort((catA, catB) => catA.translatedName.localeCompare(catB.translatedName));
  }),

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  }),

  socialProfileNetworks: [
    'website',
    'facebook',
    'instagram',
    'twitter',
    'youtube'
  ],

  actions: {
    selectVenueCategory(venueCategory) {
      this.set('selectedVenueCategory', venueCategory);

      this.setProperty('venueCategoryId', venueCategory.id);
    },

    setAddress(address) {
      this.setProperty('streetAddress', address.streetAddress);
      this.setProperty('city', address.city);
      this.setProperty('country', address.country);
      this.setProperty('postalCode', address.postalCode);
      this.setProperty('formattedAddress', address.formattedAddress);
      this.setProperty('latitude', address.latitude);
      this.setProperty('longitude', address.longitude);
    },

    didCompleteUpload() {
      this.decrementProperty('pendingUploadCount');
      this.markFormAsUploading(this.pendingUploadCount > 0);
    },

    didStartUpload() {
      this.incrementProperty('pendingUploadCount');
      this.markFormAsUploading(true);
    },

    didCreateProfileImage(image) {
      this.setProperty('profileImage', {
        id: image.id,
        imageUrl: image.thumbnailImageUrl
      });
    },

    reimportFacebookEvents() {
      this.rescrapeFacebook.perform(this.venueId);
    },
  }
});
